import React from "react";
import ReactDOM from 'react-dom/client';
import { useNavigate, Navigate } from 'react-router-dom';
import './App.css';
import { useEffect, useState, useRef, useMemo } from 'react';
import * as Auth from '@aws-amplify/auth';
import { get } from '@aws-amplify/api-rest';
import awsconfig from './aws-exports';
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { gridColumns } from './lib/grid';
import { sepElements } from "./lib/common";
import { loadRowData } from "./lib/common";
import { getUserInfo } from "./lib/common";
import { listFiles } from "./lib/common";
import { rowClassRules } from "./lib/grid";
import { printConsole } from './lib/utility'; 
import { downloadFile } from "./lib/common";
import { useActiveButton } from './ActiveButtonContext';
import { strategy } from "./lib/common";

import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { type } from "@testing-library/user-event/dist/type";
const axios = require('axios');


const myAPI="mktappapi";
let path = '/strategy';

const mboum=require('mboummarketdatalib');

const RunCollection = ({ externalCollection }) =>  {

  const navigate = useNavigate();
  const { activeButton } = useActiveButton();
  let numberOfChecked=useRef(0);

  //const [email, setEmail]=useState()
  let email = useRef();
  let errorState = useRef(false);
  const [days, setDays]=useState();
  const [forceRender, setForceRender]=useState(false);
  const [daysOffset, setDaysOffset]=useState();
  const [minInterest, setMinInyerest]=useState();
  const [maxInterest, setMaxInterest]=useState();
  const [settingsStr, setSettingsStr]=useState({"days":"30","daysOffset":"10","minInterest":"0.7","maxInterest":"1.5"});
  let data = useRef();
  const [counter, setCounter]=useState(0);
  const [error,setError]=useState('');
  const [hide, setHide]=useState("hidden");
  const [submitted, setSubmitted]=useState(false);
  //const [stocks, setStocks]=useState({});
  let stocks = useRef({});
  let [displayMessage, setDisplayMessage] = useState("Preparing ...");
  const [getDataInitiated, setGetDataInitiated] = useState(false);
  const [applyRowClassRules, setApplyRowClassRules] = useState(true);
  const [clickedRowData, setClickedRowData] = useState(null);
  let [refreshClicked, setRefreshClicked] =useState(1);
  

  let apiCalled=useRef(0);
  let symbolsDetails = useRef([]);
  //const [rowData, setRowData] = useState([]);
  let  rowData = useRef([]);
  let columnNameCellClicked = useRef("");
  let selectedRowIndex = useRef(-1);
  let lastRowClickEvent = useRef();
  let [dataLoaded, setDataLoaded] = useState(false);
  const [showGrid, setShowGrid] = useState(true); 
  let totalColumnWidth = useRef(0);
  let lastGridRef = useRef();
  let lastGridApi = useRef();
  let lastGridContainer = useRef();
  let lastFirstDataRendered = useRef();
  const [savedGridState, setSavedGridState] = useState(null);
  let sortState = useRef();
  let lastRowIndex = useRef();
  let lastColumnState = useRef();
  let gridColumnApi = useRef();
  let cellToExpandIsClicked = useRef(false);
  let sortedColumns = useRef();

  const updateGridContainerWidth = () => {
    console.log("Executing updateGridContainerWidth")
    if (!gridRef.current) {
      console.log("updateGridContainerWidth : gridRef.current is not set, assigning lastGridRef.current");
      gridRef.current = lastGridRef.current;
    } else {
      /*totalColumnWidth.current = 0;
      gridRef.current.getAllDisplayedColumns().forEach(column => {
          totalColumnWidth.current += column.getActualWidth();
      });*/
    }
    console.log("updateGridContainerWidth : gridApi is available");
    console.log(gridRef.current);
    
    console.log("updateGridContainerWidth : totalColumnWidth = " + totalColumnWidth.current);

    // Update the container's width
    let gridContainer = document.querySelector('.ag-theme-balham');
    if (gridContainer !== null) {
      lastGridContainer.current = gridContainer;
    } else {
      gridContainer = lastGridContainer.current;
    }
    console.log ("updateGridContainerWidth : gridContainer =");
    console.log(gridContainer);
    /*if (gridContainer) {
        gridContainer.style.width = `${totalColumnWidth.current}px`;
        console.log("updateGridContainerWidth : gridContainer.style.width = " + gridContainer.style.width);
    }*/
    //setGridContainerUpdated(true);
  };

  const onBackButtonClick = () => {
    console.log ("Executing onBackButtonClick");
    console.log ("onBackButtonClick : before change showGrid = " + showGrid);
    restoreGridState();
    console.log(gridApi.current);
    
    console.log ("onBackButtonClick : calling updateGridContainerWidth();");
    //updateGridContainerWidth();
    setShowGrid(true); 
    //gridApi.current.refreshCells();
    console.log ("onBackButtonClick : after change showGrid = " + showGrid);
    
    //restoreGridState();
    //onFirstDataRendered(lastFirstDataRendered.current);
    //
    
  };

  const DetailsTable = ({ data, onBack }) => {
    console.log("Executing DetailsTable");
    console.log("data = ");
    console.log(data);
    if (!data) return null;

    const excludedFields = ['id', 'details', 'expanded']; // Fields to exclude
    const filteredData = Object.entries(data).filter(([key]) => !excludedFields.includes(key));
    
    // Pair up the data entries for two columns per row
    const dataPairs = [];
    for (let i = 0; i < filteredData.length; i += 2) {
        dataPairs.push(filteredData.slice(i, i + 2));
    }

    return (
        <div style={{ textAlign: "center" }}>
            <table style={{ margin: "0 auto", borderCollapse: "collapse", width: "100%", maxWidth: "800px" }}>
                <tbody>
                    {dataPairs.map((pair, index) => (
                        <tr key={index}>
                            {pair.map(([key, value]) => (
                                <React.Fragment key={key}>
                                    <td style={{ fontWeight: "bold", border: "1px solid black", padding: "8px", backgroundColor: "lightgrey" }}>{key}</td>
                                    <td style={{ border: "1px solid black", padding: "8px" }}>{value}</td>
                                </React.Fragment>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            
            <button onClick={onBack} style={{ marginTop: "20px", border: "none", background: "none", cursor: "pointer" }}>
              <img src="back.png" alt="Back" title="Back" style={{ width: '50px', height: '50px' }} />
            </button>
        </div>
    );
  };


/*<button onClick={onBack} style={{ marginTop: "20px" }}>Back</button>
<Button style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={onBack}>
              <img src="back.png" alt="Back" title="Back" style={{ marginRight: '5px', width: '33%', marginTop: "20px" }} />
            </Button>*/

  const saveGridState = () => {
    console.log("Executing saveGridState");
    if (!gridApi.current) return;

    // Example of saving row data and column state
    const rowData = [];
    console.log(gridApi.current);
    gridApi.current.forEachNode(node => rowData.push(node.data));
    const columnState = gridApi.current.getColumnState();

    setSavedGridState({ rowData, columnState });
  };

  const restoreGridState = () => {
    console.log("Executing restoreGridState");
    gridApi.current = lastGridApi.current;
    if (!gridApi.current || !savedGridState) return;

    // Set row data and column state
    console.log(gridApi.current);
    gridApi.current.setRowData(savedGridState.rowData);
    gridApi.current.setColumnState(savedGridState.columnState);
    gridRef.current.api.setColumnState(lastColumnState.current);
    console.log(gridRef.current.getColumnState());
    const rankingSortModel = JSON.parse(localStorage.getItem('rankingSortState'));
    if (rankingSortModel) {
        gridColumnApi.setSortModel([rankingSortModel]);
    }
  };

  const onSortChanged = (event) => {
    console.log ("Executing onSortChanged");
    console.log(event);
    console.log(gridApi.current);
    setApplyRowClassRules(true);
    // Save the sort state
    if (gridApi.current && gridColumnApi.current) {
      // Get the current state of all columns
      const columnState = event.api.getColumnState();
  
      // Filter out the columns that have sorting applied
      sortedColumns.current = columnState.filter(col => col.sort);
  
      // Save the sorted columns state
      localStorage.setItem('sortedColumns', JSON.stringify(sortedColumns));
    }
  };

  function onCellClicked(event) {
    console.log("Executing onCellClicked");
    console.log(event);
    console.log(gridApi.current);
    localStorage.setItem('selectedRowIndex', event.rowIndex);

    const rowIndex = event.rowIndex;
    //const rowIndex = gridApi.current.getLastDisplayedRow();
    console.log("onCellClicked : rowindex = " + event.rowIndex);
    console.log("onCellClicked : gridApi.current.getFirstDisplayedRow() = " + gridApi.current.getFirstDisplayedRowIndex());
    console.log("onCellClicked : gridApi.current.getLastDisplayedRow() = " + gridApi.current.getLastDisplayedRowIndex());
    console.log("onCellClicked : gridApi.current.getDisplayedRowCount() = " + gridApi.current.getDisplayedRowCount());
    console.log(gridApi.current.getRowNode(rowIndex));
    console.log(gridApi.current.getRowNode(gridApi.current.getFirstDisplayedRowIndex()));
    console.log(gridApi.current.getRowNode(gridApi.current.getLastDisplayedRowIndex()));
    console.log(gridApi.current.getVerticalPixelRange());
    const rowNode = gridApi.current.getRowNode(rowIndex);

    //const rowHeight = gridApi.current.getRowHeight(); // Assuming uniform row height
    const rowHeight = 25;
    const scrollTop = gridApi.current.getVerticalPixelRange().top; // Current scroll position

    // Approximate position of the row from the top of the grid
    const rowPosition = rowNode.rowIndex * rowHeight - scrollTop;
    console.log("onCellClicked : rowPosition = " + rowPosition);
    localStorage.setItem('selectedRowPosition', rowPosition);

    //localStorage.setItem('selectedRowIndex',gridApi.current.getLastDisplayedRow());
    if (event.colDef.field === "details") {
      cellToExpandIsClicked.current = true;
      lastGridRef.current = gridRef.current;
      lastGridApi.current = gridApi.current;
      totalColumnWidth.current = 0;
      gridRef.current.getAllDisplayedColumns().forEach(column => {
          totalColumnWidth.current += column.getActualWidth();
      });
      if (gridRef.current) {
        console.log("onCellClicked : rowindex = " + event.rowIndex);
        localStorage.setItem('selectedRowIndex', parseInt(event.rowIndex));
        //localStorage.setItem('selectedRowIndex', rowIndex);
        const rowData = [];
        gridRef.current.api.forEachNode(node => rowData.push(node.data));
        lastColumnState.current = gridRef.current.getColumnState();
        gridColumnApi.current = event.api;
        console.log(lastColumnState.current);
        console.log(gridApi.current);
        console.log(gridRef.current);
      }
      //setGridContainerUpdated(false);
      setClickedRowData(event.data); // Set the text you want to display
      setShowGrid(false); // Hide the grid
    }
  }

  const getRowHeight = (params) => {
    console.log("Executing getRowHeight");
    console.log("getRowHeight : params =");
    console.log(params);
    //if (params.data.expanded) {
    if (typeof(params) !== "undefined") {
      if (rowData.current[params.data.id].expanded) {
          //params.data.details = "v";
          return 200; // Expanded row height
      } 
    }
    return 25; // Default row height
  };


  const getUserInfo = async () => {
    try {
      await Auth.fetchAuthSession();
      // Retrieve the user's email address
      const user = await Auth.fetchUserAttributes();
      console.log("getUserInfo : email = " + JSON.stringify(user.email));
      email.current = await user.email;
      return "done";
    } catch (error) {
      console.log("Error in getUserInfo: ", error);
      return "error";
    }
  };

  let initString="";

  async function listFiles() {
    await console.log("Executing listFiles");
    data.current = await JSON.parse(await downloadFile(email.current));
  }

  async function getSymbolsDetails(){
    await console.log("Executing getSymbolsDetails");
    path = '/getSymbolsDetails';
    var passApiStr=await path;
    await console.log("getSymbolsDetails : passApiStr = "+ passApiStr);
    /*await Promise.resolve(await get(myAPI, passApiStr))
      .then(async resp =>{
        
        await console.log("getSymbolsDetails : received symbols details = ");
        await console.log(resp);
        //symbolsDetails.current = await JSON.stringify(resp);
        symbolsDetails.current = await resp;
        console.log("getSymbolsDetails : symbolsDetails.current.length = " + symbolsDetails.current.length);
          
    })*/
    const response = await get({
      apiName: myAPI,
      path: passApiStr,
    }).response
    .catch(error => {
      console.log(error.message);
      errorState.current = true;
      setDisplayMessage(error.message);
    }) 
    symbolsDetails.current = await response.body.json();
    console.log("getSymbolsDetails : symbolsDetails.current.length = " + symbolsDetails.current.length);
  }

  async function getStocks(collection){
    await console.log("Executing getStocks");
    path = '/getSecuritiesCollection';
    let resp;
    var passApiStr=await path + "/"+collection;
    await console.log("getStocks : passApiStr = "+ passApiStr);
    //await Promise.resolve(await get(myAPI, passApiStr))
    const response = await get({
      apiName: myAPI,
      path: passApiStr,
    }).response
    resp = await response.body.json();
    await Promise.resolve(resp)
      .then(async () =>{
        try {
          
          await console.log("getStocks : received stocks = ");
          await console.log(resp);
          if (resp.stocks.length === 0) {
            await setShowLoading(false);
          }
          if (await JSON.stringify(stocks) !== await JSON.stringify(resp)) {
            await console.log("getStocks : I am in if \n stocks = "+JSON.stringify(stocks));
            stocks.current = await resp;
            await console.log ("getStocks : stocks.current.stocks.length = " + stocks.current.stocks.length);
            if (stocks.current.stocks.length > 600) {
              let tmpArr = [];
              for (let stkInd = 0; stkInd < 600; stkInd++) {
                tmpArr.push(stocks.current.stocks[stkInd])
              }
              stocks.current.stocks = tmpArr;
              await console.log ("getStocks : stocks.current.stocks.length = " + stocks.current.stocks.length);
            }
          }
        } catch(error) {};
        return initString;
    })
    .catch(error => {
      console.log(error.message);
      errorState.current = true;
      setDisplayMessage(error.message);
    }) 
  }

  //const [analysis, setAnalysis]=useState([]);
  let analysis = useRef([]);

  let daysNowToExp = useRef();

  let strategyInitiated = useRef(false);
  
//const [rowDataState,setRowDataSate]=useState([]);
let rowDataState = useRef([]);

  
  
  const [showLoading, setShowLoading] = useState(true);
  //const [gridApi, setGridApi] = useState(null);
  let gridApi = useRef(null);
  let numOfStocks = useRef(0);
  let countOfResponses = useRef(0);
  let countRef = useRef(0);
  //let [securitiesDbUpdated, setSecuritiesDbUpdated] = useState(false);
  let securitiesDbUpdated = useRef(false);

  function generateLimitTimestamp(offsetTimeInSeconds) {
    console.log("Executing generateTimestamp");
    const currentDate = new Date();
    let currentTimestamp = currentDate.getTime();
    let currentTimestampInt = parseInt(currentTimestamp);
    currentTimestampInt = parseInt(currentTimestampInt / 1000);
    let offsetTimestampInt = currentTimestampInt - parseInt(offsetTimeInSeconds);
    let offsetTimestamp = offsetTimestampInt.toString();
    currentTimestamp = currentTimestampInt.toString();
    console.log('Current timestamp:', currentTimestamp);
    console.log('Offset timestamp:', offsetTimestamp);
  
    return offsetTimestamp;
  }

  function encodeData(txt) {
    txt = txt.replace(/\{/g,"%7B");
    txt = txt.replace(/\}/g,"%7D");
    txt = txt.replace(/\[/g,"%5B");
    txt = txt.replace(/\]/g,"%5D");
    txt = txt.replace(/\:/g,"%3A");
    txt = txt.replace(/ /g,"%20");
    txt = txt.replace(/\,/g,"%2C");
    txt = txt.replace(/\"/g,"%22");

    return(txt);
  }

  let strategyInd = useRef();
  let getSecuritiesCalled = useRef(false);

  async function getSecuritiesInfo(securities) {
    await console.log("getSecuritiesInfo : securities = ");
    await console.log(securities);
    
      var param = {"stocks" : securities}
      await console.log("getSecuritiesInfo : response in param = " + JSON.stringify(param));
      var path = '/getSecuritiesInfo';
      var passApiStr=path + "/" + await JSON.stringify(param);
      await console.log ("getSecuritiesInfo : passApistr = " + passApiStr);
      passApiStr = await encodeData(passApiStr);
      await console.log ("getSecuritiesInfo : passApistr encoded = " + passApiStr);
      //await Promise.resolve(await get(myAPI, passApistr))
      const response = await get({
        apiName: myAPI,
        path: passApiStr,
      }).response
      .then (async () =>{
        await console.log ("getSecuritiesInfo : completed the update");
      })
      .catch(error => {
        console.log(error.message);
        errorState.current = true;
        setDisplayMessage(error.message);
      })
  }
  
  useEffect(()=>{
    console.log("Executing useEffect");
  
    console.log(gridRef);
  
    if (gridRef.current !== null) {
      gridApi.current = gridRef.current.api;
      //setGridApi(gridApi);
      console.log ("useEffect : gridApi = ")
      console.log (gridRef);
      console.log(gridApi.current);
      console.log(gridRef.current.api);
      console.log(AgGridReact);
      console.log(AgGridReact.api);
  
      if (typeof(gridApi.current) !== 'undefined') {
        console.log("useEffect : adding event listener");
        gridApi.current.addEventListener('rowDataChanged', async (event) => {
          await console.log("useEffect : event.loading = ");
          await console.log(event);
          if (!event.loading) {
            //setShowLoading(false);
          }
        });
      }
    }
    if (!securitiesDbUpdated.current && typeof(stocks.current.stocks)==='undefined') {
      Promise.resolve(getSymbolsDetails())
      .then (() => {
        Promise.resolve(getUserInfo())
        .then(async response =>{
          await Promise.resolve(await listFiles())
          .then(async response =>{
            if (typeof (stocks.current.stocks) === 'undefined') {
              await Promise.resolve(await getStocks(externalCollection))
            .then(async response => {
              try {
                var oneStock={"stocks":[]},i=0;
                var count = await Object.keys(analysis.current).length;
                await console.log("useEffect : count = "+count);
                await console.log("useEffect : type of data = " + typeof(data.current));
                await console.log("useEffect : stocks length = "+ stocks.current.stocks.length);
                await console.log("useEffect : apiCalled.current = "+ apiCalled.current);
                //if (typeof(data)!='undefined' && stocks.current.stocks.length>0 && apiCalled.current===0){
                  await console.log("***************************************");
                  await console.log("useEffect : data = "+JSON.stringify(data.current));
                  await console.log("useEffect : stocks = " + JSON.stringify(stocks.current));
                  await console.log("useEffect : stocks length = "+ stocks.current["stocks"].length);
                  apiCalled.current=1;
            
                  // new approach start
            
                  //{"pathParameters":{"stocks":["ACWI","ACWV","ACWX","AFTY"],"timestampLimit" : "1702158270"}}
            
                  if (!securitiesDbUpdated.current) {
                    //setDisplayMessage("Identifying symbol(s) with missing and expired data");
                    //await Promise.resolve(await findExpiredMissingSecurities())
                    //.then(async response => {
                      if (!getSecuritiesCalled.current) {
                        setDisplayMessage("Getting market data for the symbol(s)");
                        await Promise.resolve(await getSecuritiesInfo(stocks.current.stocks))
                          .then(async response => {
                            //await setSecuritiesDbUpdated(true);
                            securitiesDbUpdated.current = true;
                            if (!errorState.current) {
                              setDisplayMessage("Identifying profitable symbol(s)");
                            }
                            await console.log ("useeffect : securitiesDbUpdated.current = " + securitiesDbUpdated.current);
                            await console.log ("useeffect : starting loop for strategy");
                            await console.log ("useeffect : stocks[stocks].length = " + stocks.current["stocks"].length);
                            var stocksStr = "";
                            for (strategyInd.current=0; strategyInd.current<stocks.current["stocks"].length; strategyInd.current++) {
                              stocksStr = stocksStr + stocks.current["stocks"][strategyInd.current];
                              if (strategyInd.current < stocks.current["stocks"].length - 1) {
                                stocksStr = stocksStr + ",";
                              }
                            }
                            /*for (strategyInd.current=0; strategyInd.current<stocks.current["stocks"].length; strategyInd.current++) {
                            //for (strategyInd.current=0; strategyInd.current<100; strategyInd.current++) {
                              oneStock["stocks"]=stocks.current["stocks"][strategyInd.current];
                              await console.log("useeffect : oneStock = "+JSON.stringify(oneStock));
                              var params={...data.current, ...oneStock};
                              await console.log("useEffect : params = "+JSON.stringify(params));
                              var paramsStr=JSON.stringify(params);
                              await console.log("useEffect : paramsStr = " + paramsStr);
                              displayMessage.current = "Processing " + oneStock["stocks"] + " ( " + (strategyInd.current + 1) + " out of " + stocks.current["stocks"].length + " )";
                              await strategy(paramsStr);
                            }*/
                            
                            oneStock["stocks"] = stocksStr;
                            var params={...data.current, ...oneStock};
                            var paramsStr=JSON.stringify(params);
                            Promise.resolve(await strategy(paramsStr, activeButton))
                            .then(async (strategyAnalysis) => {
                              await console.log("useeffect : strategyAnalysis");
                              await console.log(strategyAnalysis);
                              count = Object.keys(strategyAnalysis).length;
                              let rowdata = [];
                              rowdata = await loadRowData(strategyAnalysis, daysNowToExp.current, activeButton);
                              await console.log("useeffect : rowDataState.current.length = " + rowDataState.current.length);
                              await console.log("useeffect : count = " + count);
                              await console.log("useeffect : rowdata.length = " + rowdata.length);
                              if (rowDataState.current.length<count && count!==0) {//rowDataState.length===0 && 
                                //setRowDataSate(rowdata);
                                rowDataState.current = rowdata;
                                console.log("useeffect : rowdata = " + JSON.stringify(rowdata[0]));
                              }
                              getSecuritiesCalled.current = true;
                              if (!errorState.current) {
                                setShowLoading(false);
                              }
                            })
                            .catch(error => {
                              console.log(error.message);
                              errorState.current = true;
                              setDisplayMessage(error.message);
                            })
                          })
                        
                      .catch(error => {
                        getSecuritiesCalled.current = true;
                        console.log(error)
                      })
                    }
                  //})
                  } else {
                    /*console.log ("useeffect : starting loop for strategy");
                    var params={...data, ...stocks.current["stocks"]};
                    var paramsStr=JSON.stringify(params);
                    for (i=0; i<stocks.current["stocks"].length; i++) {
                      oneStock["stocks"]=stocks.current["stocks"][i];
                      console.log("oneStock = "+JSON.stringify(oneStock));
                      var params={...data.current, ...oneStock};
                      console.log("useEffect : params = "+JSON.stringify(params));
                      var paramsStr=JSON.stringify(params);
                      console.log("useEffect : paramsStr = " + paramsStr);
                      displayMessage.current = "Processing " + oneStock["stocks"] + " ( " + (strategyInd.current + 1) + " out of " + stocks.current["stocks"].length + " )";
                      strategy(paramsStr);
                    }
                    setShowLoading(false);*/
                  }
                //}
                
              } catch (e) {
                if (!errorState.current) {
                  setShowLoading(false);
                }
              }
            })
            .catch(error => {
              console.log(error.message);
              errorState.current = true;
              setDisplayMessage(error.message);
            })
            }
          })
          .catch(error => {
            console.log(error.message);
            errorState.current = true;
            setDisplayMessage(error.message);
          })
        })
        .catch(error => {
          console.log(error.message);
          errorState.current = true;
          setDisplayMessage(error.message);
        })
      })
      .catch(error => {
        console.log(error.message);
        errorState.current = true;
        setDisplayMessage(error.message);
      })
    }

    },[dataLoaded, getDataInitiated, applyRowClassRules, showGrid, refreshClicked])

function printStocks() {
  
  console.log("I'M IN PRINTSTOCK");
  console.log("analysis = "+JSON.stringify(analysis));
  console.log("analysis length = "+analysis.length);
  console.log("typeof analysis.length = "+ typeof analysis.length )
  return (
    <ul>
      {typeof analysis.length!=='undefined'
      ?
        <text>Yes</text>
      
      : <text>None</text>
    }
    </ul>
  )
}

const gridRef = useRef();
const columns=gridColumns(symbolsDetails.current, rowData.current, selectedRowIndex.current, activeButton, data.current);

const onSelectionChanged=() =>{
  console.log("Executing onSelectionChanged");
  var commitDelete="";
  console.log("gridRef.current = ", gridRef.current);
  let selectedRowsNumber=gridRef.current.api.getSelectedRows().length;
  console.log("gridRef.current.getSelectedRows().length = " + selectedRowsNumber);
  /*if (gridRef.current.getSelectedRows().length>0) {
    commitDelete="commit";
  } else {
    commitDelete="delete";
  }*/
  if (selectedRowsNumber>=numberOfChecked.current) {
    commitDelete="commit";
    numberOfChecked.current++;
  } else {
    commitDelete="delete";
    numberOfChecked.current--;
  }

  console.log("commitDelete = "+commitDelete);
  //const selectedRows = gridRef.current.getSelectedRows();
  
  
  console.log("gridRef");
  console.log(gridRef);
  console.log("getSelectedNodes");
  console.log(gridRef.current.api.getSelectedNodes());
  console.log("checkboxSelected");
  console.log(gridRef.current.checkboxSelected);
  console.log("getDisplayedRowCount = "+gridRef.current.api.getDisplayedRowCount());
  const selectedRowIndex=gridRef.current.api.getFocusedCell().rowIndex;
  console.log("selectedRowIndex = "+selectedRowIndex);
  console.log("getDisplayedRowAtIndex");
  console.log(gridRef.current.api.getDisplayedRowAtIndex(selectedRowIndex));

  let selectedRows;
  try {
    selectedRows = gridRef.current.api.getDisplayedRowAtIndex(selectedRowIndex).data;
  } catch (e) {
    console.log("onSelectionChanged : error: selectedRows = "+e);
  }
  

  console.log("selectedRows");
  console.log(selectedRows);

  let rowJson={"security":{}};
  let dt=Date().toString();
  let timestamp=Date.parse(dt).toString();
  try {
    rowJson.security["id"]=timestamp;
    rowJson.security["timestamp"]=dt;
    rowJson.security["symbol"]=selectedRows.security.toUpperCase();
    rowJson.security["rank"]=selectedRows.ranking.toString();
    rowJson.security["offer"]=selectedRows.stockOffer.toString();
    rowJson.security["exp"]=selectedRows.expiration.toString();
    rowJson.security["strike"]=selectedRows.strike.toString();
    rowJson.security["bid"]=selectedRows.bid.toString();
    rowJson.security["ask"]=selectedRows.offer.toString();
    rowJson.security["int"]=selectedRows.openInterest.toString();
    rowJson.security["vol"]=selectedRows.volume.toString();
    rowJson.security["profit"]=selectedRows.profit.toString();
    rowJson.security["annual"]=selectedRows.annualProfit.toString();
    rowJson.security["depth"]=selectedRows.depth.toString();
    rowJson.security["wavg"]=selectedRows.wAvgChange.toString();
    rowJson.security["max"]=selectedRows.maxChange.toString();
    rowJson.security["volatility"]=selectedRows.impliedVol.toString();
    rowJson.security["volatilityPeriod"]=selectedRows.impliedPeriod.toString();
    rowJson.security["volMinPeriod"]=selectedRows.minPrice.toString();
    rowJson.security["volMaxPeriod"]=selectedRows.maxPrice.toString();
    rowJson.security["email"]=email.current;

    if (commitDelete==="commit") {
      path = '/storetransaction';
      let transaction = new URLSearchParams({ data: JSON.stringify(rowJson) }).toString();
      transaction = transaction.replace("data=","");
      transaction = transaction.replace(/"%3A"/g,/":"/)
      //let passApistr=path + "/" + JSON.stringify(rowJson);
      let passApiStr=path + "/" + transaction;
      console.log("onSelectionChanged : passApistr = " +passApiStr);
      //get(myAPI, passApistr)
      get({
        apiName: myAPI,
        path: passApiStr,
      }).response
        .then(async resp =>{
          await console.log("onSelectionChanged : resp = ");
          await console.log(resp);
        })
        .catch(error => {
          console.log(error.message);
          errorState.current = true;
          setDisplayMessage(error.message);
        })
    }

    if (commitDelete==="delete") {
      path = '/deletetransaction';
      let transaction = new URLSearchParams({ data: JSON.stringify(rowJson) }).toString();
      transaction = transaction.replace("data=","");
      transaction = transaction.replace(/"%3A"/g,/":"/)
      let passApiStr=path + "/" + transaction;
      console.log("onSelectionChanged : passApistr = " +passApiStr);
      //get(myAPI, passApistr)
      get({
        apiName: myAPI,
        path: passApiStr,
      }).response
        .then(async resp =>{
          await console.log("onSelectionChanged : resp = ");
          await console.log(resp);
        })
        .catch(error => {
          console.log(error.message);
          errorState.current = true;
          setDisplayMessage(error.message);
        })
    }
  } catch(e) {
    console.log("onSelectionChanged : error: " + e);
  }
}

function onFirstDataRendered(params) {
  console.log("Executing onFirstDataRendered");
  lastFirstDataRendered.current = params;
  console.log(params);
  var allColumnIds = [];
  params.api.getColumns().forEach(function(column) {
      allColumnIds.push(column.colId);
  });
  params.api.autoSizeColumns(allColumnIds);
  if (typeof(gridApi.current) !== 'undefined') {
    //setShowLoading(false);
    console.log("onFirstDataRendered : adding event listener");
    /*gridApi.current.addEventListener('rowDataChanged', (event) => {
      console.log("onFirstDataRendered : event.loading = ");
      console.log(event);
      if (!event.loading) {
        //setShowLoading(false);
      }
    });*/
  }
  console.log ("onFirstDataRendered : cellToExpandIsClicked.current = " + cellToExpandIsClicked.current);
  if (cellToExpandIsClicked.current) {
    //const savedSortState = localStorage.getItem('sortedColumns');
    console.log(sortedColumns.current)
    const savedSortState = sortedColumns.current;
    if (savedSortState && gridColumnApi.current) {
      //const sortedColumns = JSON.parse(savedSortState);
      const sortedColumns = savedSortState;

      gridColumnApi.current.api.applyColumnState({
        state: sortedColumns,
        applyOrder: true,
      });
    }
    const selectedRowIndex = localStorage.getItem('selectedRowIndex');
    const selectedRowPosition = localStorage.getItem('selectedRowPosition');
    if (selectedRowIndex !== null) {
      params.api.ensureIndexVisible(parseInt(selectedRowIndex));
    }
    cellToExpandIsClicked.current = false;
  }
}
  if (getDataInitiated === false) {
    setGetDataInitiated(true);
  };

  const gridOptions = {
    //domLayout: 'autoHeight', // This is important for rendering HTML in cells
    // Add other grid options as needed
    onSortChanged: () => {
      // Set the flag to false when sorting starts
      setApplyRowClassRules(false);
    },
    onSortChanged: onSortChanged,
    /*onSortChanged: () => {
      // Set the flag back to true when sorting is finished
      setApplyRowClassRules(true);
    },*/
  };

  const onGridReady = params => {
    console.log("Executing onGridReady");
    console.log(params);
    gridApi.current = params.api;
    gridColumnApi.current = params.api;

     console.log("onGridReady : email.current = ", email.current);
         if (typeof(email.current) === "undefined") {
           Promise.resolve(getUserInfo())
           .then(response => {
             console.log("onGridReady : getUserInfo() : email.current = ", email.current);
             Promise.resolve(listFiles())
             .then(response => {
               console.log("onGridReady : listFiles() : data.current = ", data.current);
               console.log(params);
               gridApi.current = params.api;
               gridColumnApi.current = params.api;
     
               if (typeof(data.current.rank) === "undefined") {
                 data.current.rank = "ALL";
               }
               if (typeof(data.current.strikeMin) === "undefined") {
                 data.current.strikeMin = "";
               }
               if (typeof(data.current.strikeMax) === "undefined") {
                 data.current.strikeMax = "";
               }
               if (typeof(data.current.profitSort) === "undefined") {
                 data.current.profitSort = "";
               }
     
               // Apply a filter for rank if it is not "ALL"
              if (data.current.rank !== "ALL") {
                console.log("onGridReady : data.current.rank = " + data.current.rank);

                // Get the filter instance for the 'ranking' column asynchronously
                params.api.getColumnFilterInstance('ranking').then((filterInstanceRank) => {
                  // Ensure the filter instance exists
                  if (filterInstanceRank) {
                    filterInstanceRank.setModel({
                      type: 'equals', // Filter for values equal to rank
                      filter: Number(data.current.rank), // Convert rank to a number
                    });
                    params.api.onFilterChanged(); // Apply the filter
                  } else {
                    console.error("Filter instance for 'ranking' not found.");
                  }
                });
              }

              // Apply a filter for strikeMin only
              if (data.current.strikeMax === "" && data.current.strikeMin !== "") {
                console.log("onGridReady : data.current.strikeMin = " + data.current.strikeMin);
                console.log("onGridReady : data.current.strikeMax = " + data.current.strikeMax);

                // Get the filter instance for the 'strike' column asynchronously
                params.api.getColumnFilterInstance('strike').then((filterInstanceStrike) => {
                  // Ensure the filter instance exists
                  if (filterInstanceStrike) {
                    filterInstanceStrike.setModel({
                      type: 'greaterThanOrEqual', // Filter for values >= strikeMin
                      filter: Number(data.current.strikeMin), // Convert strikeMin to a number
                    });
                    params.api.onFilterChanged(); // Apply the filter
                  } else {
                    console.error("Filter instance for 'strike' not found.");
                  }
                });
              }

              // Apply a filter for strikeMax only
              if (data.current.strikeMin === "" && data.current.strikeMax !== "") {
                console.log("onGridReady : data.current.strikeMin = " + data.current.strikeMin);
                console.log("onGridReady : data.current.strikeMax = " + data.current.strikeMax);

                // Get the filter instance for the 'strike' column asynchronously
                params.api.getColumnFilterInstance('strike').then((filterInstanceStrike) => {
                  // Ensure the filter instance exists
                  if (filterInstanceStrike) {
                    filterInstanceStrike.setModel({
                      type: 'lessThanOrEqual', // Filter for values <= strikeMax
                      filter: Number(data.current.strikeMax), // Convert strikeMax to a number
                    });
                    params.api.onFilterChanged(); // Apply the filter
                  } else {
                    console.error("Filter instance for 'strike' not found.");
                  }
                });
              }

              // Apply a combined filter for strikeMin and strikeMax
              if (data.current.strikeMin !== "" && data.current.strikeMax !== "") {
                console.log("onGridReady : data.current.strikeMin = " + data.current.strikeMin);
                console.log("onGridReady : data.current.strikeMax = " + data.current.strikeMax);

                // Get the filter instance for the 'strike' column asynchronously
                params.api.getColumnFilterInstance('strike').then((filterInstanceStrike) => {
                  // Ensure the filter instance exists
                  if (filterInstanceStrike) {
                    filterInstanceStrike.setModel({
                      condition1: {
                        type: 'greaterThanOrEqual', // Filter for values >= strikeMin
                        filter: Number(data.current.strikeMin), // Convert strikeMin to a number
                      },
                      condition2: {
                        type: 'lessThanOrEqual', // Filter for values <= strikeMax
                        filter: Number(data.current.strikeMax), // Convert strikeMax to a number
                      },
                      operator: 'AND', // Combine both conditions with AND
                    });
                    params.api.onFilterChanged(); // Apply the filter
                  } else {
                    console.error("Filter instance for 'strike' not found.");
                  }
                });
              }
           
               if (data.current.profitSort !== "") {
                 console.log("onGridReady : data.current.profitSort = " + data.current.profitSort);
               
                 const sortDirection = data.current.profitSort === "DESC" ? "desc" : "asc";
               
                 params.api.applyColumnState({
                   state: [
                     {
                       colId: 'profit', // The column ID to sort
                       sort: sortDirection, // Sort direction based on data.current.profit
                     },
                   ],
                   applyOrder: true, // Ensures the order of sorting is applied
                 });
               
                 console.log(`onGridReady : Sorting by profit in ${sortDirection} order`);
               }
             })
           })
         }
  
    // Restore sort state and scroll position here if needed
  };

  

  function handleRefresh() {
    console.log("Executing handleRefresh");
  
    // Reset necessary state and variables
    securitiesDbUpdated.current = false; // Reset the securities database state
    stocks.current.stocks = undefined; // Clear the current stocks data
    getSecuritiesCalled.current = false; // Ensure securities call flag is reset
    //stocksDownloaded.current = false; // Reset downloaded state
  
    // Clear the grid data and show loading
    rowDataState.current = []; // Clear current row data
    setShowLoading(true); // Display loading spinner/message
    setDisplayMessage("Refreshing data..."); // Display a user-friendly message
  
    // Trigger useEffect by toggling a state
    setGetDataInitiated(false); // Reset data fetch state
    setTimeout(() => {
      setGetDataInitiated(true); // Retrigger data fetching
    }, 100); // Small delay to ensure proper state update

    setRefreshClicked(prev => prev + 1); // Trigger a re-render
  
    console.log("handleRefresh completed");
  }

  return (
    
    <View className="AppFullScreen">
        {showGrid ? (
          <div className="ag-theme-balham" style={{width: '90%', height:500, maxWidth: '1210px'}}>
            {showLoading && (
              <div className="blink_me">{displayMessage}</div>
            )}
            <div className="green_text">B/W - Analysis For {externalCollection} - {activeButton}</div>
            <AgGridReact
              gridOptions={gridOptions}
              //onColumnHeaderClicked={onColumnHeaderClicked}
              rowClassRules={activeButton === 'inTheMoney' ? rowClassRules : null}
              rowData={rowDataState.current}
              columnDefs={columns}
              suppressRowClickSelection={true}
              rowSelection={'multiple'}
              ref={gridRef}
              onFirstDataRendered={onFirstDataRendered}
              onSelectionChanged={onSelectionChanged}
              onSortChanged={onSortChanged}
              //onRowClicked={onRowClicked}
              onCellClicked={onCellClicked}
              onGridReady={onGridReady}
              //getRowHeight={getRowHeight}
            />  
          </div>
        ) : (
          <DetailsTable data={clickedRowData} onBack={onBackButtonClick} />
        )}
        {/* added refresh button */}
        <div className="buttonContainer">
          <Button className="buttonStyle" onClick={() => { handleRefresh(); } }>Refresh</Button>
        </div>
    </View>
  );
}

/*
              frameworkComponents={{
                  myDetailRenderer: MyDetailRenderer,
              }}
*/

export default withAuthenticator(RunCollection);

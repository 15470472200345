import React from "react";
import ReactDOM from 'react-dom/client';
import { useNavigate, Navigate } from 'react-router-dom';
import './App.css';
import { useEffect, useState, useRef } from 'react'

import { get } from '@aws-amplify/api-rest';
import awsconfig from './aws-exports';
import { printConsole } from './lib/utility'; 

import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { type } from "@testing-library/user-event/dist/type";

import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';


const myAPI="mktappapi";
let path = '/getsettings';

const List = ({ signOut }) => {
  printConsole("I am in List");
  const navigate = useNavigate();

  const [rowDataState,setRowDataSate]=useState([]);
  let analysis = useRef([]);

  let initString="";

  async function downloadStocks() {
    printConsole("Executing downloadStocks");
    path = '/getListEtfs';
    var passApiStr=await path;
    await console.log("downloadStocks : passApiStr = "+ passApiStr);
    /*await Promise.resolve(await get(myAPI, passApiStr))
      .then(async resp =>{
        await printConsole("downloadStocks : received symbols details = ");
        await console.log(resp);
        analysis.current = await resp;
    })*/
    const response = await get({
      apiName: myAPI,
      path: passApiStr,
    }).response
    .catch(error => {
      console.log("downloadStocks : error = ", error.message);
    });
    analysis.current = await response.body.json();
    await console.log("downloadStocks : analysis.current = ", analysis.current);
  }

  async function printStocks() {
    printConsole("Executing printStocks");
    try {
      await downloadStocks()
      .then(() => {
        if (analysis.current && analysis.current.length > 0) {
          const rowdata = analysis.current.map((item) => ({
            symbol: item.symbol,
            type: item.type,
            name: item.name,
            exchange: item.exchange,
            leveraged: item.leveraged,
          }));
    
          console.log("printStocks: Row Data:", rowdata); // Debug rowData before updating state
          setRowDataSate(rowdata); // Update state with row data
        } else {
          console.warn("printStocks: No data available in analysis.current");
        }
      })
      
    } catch (error) {
      console.error("Error in printStocks:", error.message);
    }
  }

  function getSymInfo(symbol) {
    let info = {};
    let name = "";
    let description = "";
    let exchange = "";
    let leveraged = "";
    let type = "";
    for (var symInd = 0; symInd < analysis.current.length; symInd++) {
      let dbSymbol = analysis.current[symInd]["symbol"].toString();
      if (dbSymbol === symbol) {
        exchange = analysis.current[symInd]["exchange"].toString();
        name = analysis.current[symInd]["name"].toString();
        type = analysis.current[symInd]["type"].toString();
        leveraged = analysis.current[symInd]["leveraged"].toString();
        symInd = analysis.current.length;
      }
    }
    info = {"symbol":symbol, "name":name, "type":type, "exchange":exchange, "leveraged":leveraged};
    return info;
  }

  function escapeHtml(unsafe) {
    return unsafe.replace(/[&<"']/g, match => ({
        '&': '&amp;',
        '<': '&lt;',
        '"': '&quot;',
        "'": '&#39;'
    }[match]));
  }

  class CustomCellRenderer extends React.Component {
    render() {
      const { value, data } = this.props; // value is the cell value, data is the entire row
      console.log("Renderer Props: ", this.props); // Log all props for debugging
  
      // If value is undefined, log a fallback error message
      if (!value) {
        console.error("CustomCellRenderer: 'value' is undefined for the current cell.");
        return null;
      }
  
      const safeValueSymbol = escapeHtml(value);
      const info = getSymInfo(safeValueSymbol);
      const exchange = info.exchange || "N/A";
      const leveraged = info.leveraged || "N/A";
      const name = escapeHtml(info.name || "Unknown");
      
      const safeValueExchange = escapeHtml(exchange);
  
      return (
        <a
          href={`https://www.google.com/finance/quote/${safeValueSymbol}:${safeValueExchange}?window=1Y`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {safeValueSymbol}
        </a>
      );
    }
  }

  const columns = [
    {
      headerName: "Symbol",
      field: "symbol", // Matches key in rowDataState
      sortable: true,
      filter: true,
      minWidth: 10,
      maxWidth: 100,
      resizable: true,
      cellRenderer: CustomCellRenderer, // Reference to the custom cell renderer
    },
    { headerName: "Type", field: "type", sortable: true, filter: true, minWidth: 10, maxWidth: 100, resizable: true },
    { headerName: "Full Name", field: "name", sortable: true, filter: true, width: 400, resizable: true },
    { headerName: "Exchange", field: "exchange", sortable: true, filter: true, minWidth: 10, maxWidth: 100, resizable: true },
    { headerName: "Leveraged", field: "leveraged", sortable: true, filter: true, minWidth: 10, maxWidth: 110, resizable: true },
  ];

useEffect(()=>{
  Promise.resolve(printStocks())
    .then(() => {
      console.log("rowDataState:", rowDataState); // Ensure this contains data
    })
    .catch((error) => {
      console.error("Error in printStocks:", error.message);
    });

},[])


  return (
    <div className="AppFullScreen">
      <div className="MenuStyle">
        <div className="ag-theme-balham" style={{height:500, width:830}}>
        <div className="green_text">List Of ETFs</div>
          <AgGridReact
            rowData={rowDataState}
            columnDefs={columns}
          />
        </div>
      </div>
    </div>
  );
};

export default withAuthenticator(List);